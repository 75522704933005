


















import {Vue, Component} from 'vue-property-decorator';
import {Meta} from '@sophosoft/vue-meta-decorator';
import MonthlyAssetStatuses from '@/components/dashboard/cards/charts/MonthlyAssetStatuses.vue';

@Component({
  components: {
    MonthlyAssetStatuses,
  },
})
export default class Dashboard extends Vue {
  @Meta
  private getMetaInfo() {
    return {
      title: this.$it('menu.management.dashboard.title', 'Management Dashboard') as string,
    };
  }
}
