var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{attrs:{"title":_vm.$it('chart.asset-status-by-month.title', 'Asset Status by month'),"info":_vm.$it('chart.asset-status-by-month.description', 'This graph shows the amount of assets by status, assets without a status are not included.'),"guttered":false}},[_c('apollo-query',{ref:"apolloQuery",attrs:{"client-id":"auctionGraphqlClient","query":require('@/graphql/queries/assets/AssetStatistics.gql'),"update":_vm.update},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
var isLoading = ref.isLoading;
return [(loading)?_c('div',{staticClass:"loading"},[_c('spinner',{attrs:{"color":"#D5D5D5"}})],1):(data)?_c('vue-frappe',{ref:"chart",staticClass:"statistics",attrs:{"colors":_vm.colors,"labels":data.labels,"dataSets":data.dataSets,"height":200,"tooltipOptions":{formatTooltipY: function (d) { return d + ' ' + _vm.$it('global.assets', 'Assets'); }},"isNavigable":true,"id":"assetStatusCharts","type":"bar"},on:{"select":_vm.select}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }