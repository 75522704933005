































import {Component, Vue} from 'vue-property-decorator';
import { VueFrappe } from 'vue2-frappe';
import Card from '@/layouts/back-office/elements/Card.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {
    Card,
    Spinner,
    VueFrappe,
  },
})

export default class MonthlyAssetStatuses extends Vue {
  private statuses: string[] = [];

  private get colors() {
    return [
      '#8ABF59',
      '#F3B85E',
      '#d88e0e',
      '#563F7A',
      '#563F7A',
      '#666666',
      '#f9d89f',
      '#d88e0e',
      '#2197AE',
    ];
  }

  private select(e: any) {
    this.$router.push({
      name: 'assets',
      query: {
        'paginate-assets-filter-statuses': this.statuses[e.index],
      },
    });
  }

  private update(data: any) {
    this.statuses = data.paginateAssets?.statistics?.byStatus?.map((s: any) => s.id);

    const statuses = data.paginateAssetStatuses?.data?.reduce((p: any, c: any) => {
      p[c.id] = c.name;
      return p;
    }, {});

    return {
      labels: data.paginateAssets?.statistics?.byStatus?.map((s: any) => statuses[s.id]),
      dataSets: [
        {
          values: data.paginateAssets?.statistics?.byStatus?.map((s: any) => s.amount),
          chartType: 'bar',
          name: this.$it('global.asset-status', 'Asset Status'),
        },
      ],
    };
  }
}

